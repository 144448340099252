import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { animate, state, style, transition, trigger} from '@angular/animations';
import { MatDialog} from '@angular/material/dialog';
import { BotService } from '../services/bot.service';
import { ExcelService } from '../services/excel.service';
import { of, from} from 'rxjs';
import { distinct } from 'rxjs/operators';
import { startOfDay, parseISO, format } from 'date-fns'



import { StickyStyler } from '@angular/cdk/table';
import { formatDate } from '@angular/common';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

interface Op {
  abertura: Abertura;
  fechamento: Fechamento;
  status: string;
  pontos: number;
}

interface Fechamento {
  hora: string;
  preco: number;
  nivel: number;
  preco_vwap: number;
  vwap: string;
  preco_media: number;
  media: string;
}

interface Abertura {
  data: string;
  hora: string;
  preco: number;
  nivel: number;
  id: number;
  preco_vwap: number;
  vwap: string;
  preco_media: number;
  media: string;
}

@Component({
  selector: 'app-estatisticavwp',
  templateUrl: './estatisticavwm.component.html',
  styleUrls: ['./estatisticavwm.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class EstatisticavwmComponent implements OnInit {

  fibos:any = [];
  estatisticas;
  form;
  open;
  stop;
  profit;
  niveis:any = [];
  
  abertura:any = {}
  fechamento:any = {}
  status

  horaStart:string ;
  horaEnd:string ;
  MediaVwap;

  dataSource:any = [];
  resultado:Op[]  = [];
  operacores = [];
  stopsConsolidados = [];

  ptsProfit = 0;
  ptsStop = 0;
  ptsProfitTempo = 0;
  ptsStopTempo = 0;
  ptsTotal = 0;

  tempPts = 0;

  displayedColumns =  [
    'vwap',
    'total', 
    'profit',
    'stop',
    'pontos'
  ];

  constructor(
    private formBuilder: FormBuilder,
    private bot: BotService,
    public dialog: MatDialog,
    private excelService:ExcelService
  ) {
    this.loadFibos();
    this.form = this.formBuilder.group({
      tipo: '',
      start: '',
      end: '',
      open: '',
      stop: '',
      profit: ''
    })
   }
   
  ngOnInit() {
    this.horaStart = "09:00:00";
    this.horaEnd = "18:00:00";
    this.MediaVwap = false;
    this.displayedColumns = [
      'vwap',
      'total', 
      'profit',
      'stop',
      'pontos'
    ];
  }

  loadFibos() {
    this.bot.getEstatisticaFibos2().subscribe((res:any) =>{
      this.fibos = res.data;
    });
  }

  onChange(data){
    console.log(data);
    this.bot.getEstatisticaNiveis2(data).subscribe((res:any) =>{
      this.niveis = res.data;
    });
  }

  filterFibo(data){
    let res = this.niveis.filter(d => d.value == data);
    return res[0].nome;
  }

  onSubmit(data){
    this.operacores = [];
    this.bot.getEstatisticas2(data).subscribe((res:any) =>{
      this.operacores = res.data;
      this.filtrar();
    });
  }

  transformNiveis(tipo, val){
    let n = val.split(",");
    let up = this.niveis.find(x => x.value == n[0]);
    let down = this.niveis.find(x => x.value == n[1]);
    return `${up.nome} > ${tipo} > ${down.nome}`;
  }

  async filtrar(){
    this.ptsTotal = 0;
    this.dataSource = [];
    let inicio  = this.converteSegundos(this.horaStart);
    let final   = this.converteSegundos(this.horaEnd);
    this.resultado = await this.operacores;
    if(this.MediaVwap == true){
      if(this.form.get('tipo').value == "FiboAlta"){
        this.resultado = await this.resultado.filter(e => {
          let op_hora = this.converteSegundos(e.abertura.hora);
          if(e.abertura.preco > e.abertura.preco_media && e.abertura.preco_media > e.abertura.preco_vwap && op_hora >= inicio && op_hora <= final ){
            this.ptsTotal += e.pontos;
            return true
          }else{
            return false
          }
        });
      }else{
        this.resultado = await this.resultado.filter(e => { 
          let op_hora = this.converteSegundos(e.abertura.hora);
          if( e.abertura.preco < e.abertura.preco_media && e.abertura.preco_media < e.abertura.preco_vwap && op_hora >= inicio && op_hora <= final ){
            this.ptsTotal += e.pontos;
            return true;
          }else {
            return false;
          }
        });
      }
    }else{
      this.resultado = await this.resultado.filter(e => {
        let op_hora = this.converteSegundos(e.abertura.hora);
        if(op_hora >= inicio && op_hora <= final ){
          this.ptsTotal += e.pontos;
          return true;
        }else{
          return false;
        }
      });
    }
    let source$ = from(this.resultado);
    source$.pipe(
      distinct((e:any) => e.abertura.vwap)
    ).subscribe(async (x) => {
      this.tempPts = 0;
      let data = {
        vwap: this.transformNiveis("V", x.abertura.vwap),
        total: this.resultado.filter(e => e.abertura.vwap == x.abertura.vwap).map(e => { this.tempPts += e.pontos }).length, 
        profit: this.resultado.filter(e => (e.abertura.vwap == x.abertura.vwap && e.status == "profit")).length,
        stop: this.resultado.filter(e => (e.abertura.vwap == x.abertura.vwap && e.status == "stop")).length,
        pontos: this.tempPts
      }
      this.dataSource.push(data);
    });
  }

  sqStops(){
    this.stopsConsolidados = [];
    let stop = [];
    this.resultado.forEach(e => {
      if(e.status == 'stop'){
        stop.push(e);
      }else{
        if(stop.length >= 3){
          let i = this.stopsConsolidados.findIndex(e => e.tamanho == stop.length);
          console.log(i);
          if(i != -1){
            this.stopsConsolidados[i].sequencias.push(stop);
          }else{
            this.stopsConsolidados.push({tamanho: stop.length, sequencias: [ stop ] });
          }
          stop = [];
        }else{
          stop = [];
        }
      }
    });
  }

  somapts(data){
    let result = 0;
    data.forEach(e => {
      result += e.pontos;
    });
    return result;
  }
 
  countProfit(){
    this.ptsProfit = 0;
    let resultado = this.resultado.filter(e => e.status == "profit")
      .map(x => { this.ptsProfit += x.pontos });
    return resultado.length;
  }

  countStop(){
    this.ptsStop = 0;
    let resultado = this.resultado.filter(e => e.status == "stop")
      .map(x => { this.ptsStop += x.pontos });;
    return resultado.length;
  }

  countProfitTempo(){
    this.ptsProfitTempo = 0;
    let resultado = this.resultado.filter(e => e.status == "profit_tempo")
      .map(x => { this.ptsProfitTempo += x.pontos });
    return resultado.length;
  }

  countStopTempo(){
    this.ptsStopTempo = 0;
    let resultado = this.resultado.filter(e => e.status == "stop_tempo")
      .map(x => { this.ptsStopTempo += x.pontos });
    return resultado.length;
  }

  converteSegundos(data){
    let a = data.split(":");
    return (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]); 
  }

  clickExecelSqStops(){
    const inputs:any = []
    const data:any = [];
    this.stopsConsolidados.map(e => {
      let tamanho = e.tamanho;
      e.sequencias.map((s, i) => {
        s.map(e => {
          const date = e.abertura.data.split("-");
          let result:any = {
            'tipo': this.createTipo(),
            'ano': date[0],
            'mes': date[1],
            'dia': date[2],
            'abertura.hora': e.abertura.hora,
            'abertura.preco': e.abertura.preco,
            'Nível preço abertura': this.filterFibo(e.abertura.nivel),
            'média abertura / distancia preco': this.precoMedia(e.abertura.nivel, e.abertura.media),
            'abertura.preco_vwap': e.abertura.preco_vwap,
            'nível vwap abertura': this.transformNiveis("V", e.abertura.vwap),
            'abertura.preco_media': e.abertura.preco_media,
            'Nivel média abertura': this.transformNiveis("M", e.abertura.media),
            'fechamento.hora': e.fechamento.hora,
            'fechamento.preco': e.fechamento.preco,
            'fechamento.nivel': this.filterFibo(e.fechamento.nivel),
            'fechamento.preco_vwap': e.fechamento.preco_vwap,
            'Nivel vwap fechamento': this.transformNiveis("V", e.fechamento.vwap),
            'fechamento.preco_media': e.fechamento.preco_media,
            'Nivel media fechamento': this.transformNiveis("M", e.fechamento.media),
            'status': e.status,
            'statusNumero': this.transNumero(e.status, e.fechamento.nivel),
            'pontos': e.pontos,
            'Tamanho Sequencia' : tamanho,
            'Ordem Sequencia': i
          };
          data.push(result);
        })  
      })
    });
    this.excelService.exportAsExcelFile(inputs, data, 'SequenciaStops');
  }

  clickExcel(){
    const inputs:any = [{
      'fibo': this.form.get('tipo').value,
      'Inicio': this.form.get('start').value,
      'Final': this.form.get('end').value,
      'Profit': this.form.get('profit').value+" - Nivel acima",
      'Stop': this.form.get('stop').value+" - Nivel abaixo",
    }];
    const data:any = [];
    this.resultado.forEach(e => {
      const date = e.abertura.data.split("-");
      let result:any = {
        'tipo': this.createTipo(),
        'ano': date[0],
        'mes': date[1],
        'dia': date[2],
        'abertura.hora': e.abertura.hora,
        'abertura.preco': e.abertura.preco,
        'Nível preço abertura': this.filterFibo(e.abertura.nivel),
        'média abertura / distancia preco': this.precoMedia(e.abertura.nivel, e.abertura.media),
        'abertura.preco_vwap': e.abertura.preco_vwap,
        'nível vwap abertura': this.transformNiveis("V", e.abertura.vwap),
        'abertura.preco_media': e.abertura.preco_media,
        'Nivel média abertura': this.transformNiveis("M", e.abertura.media),
        'fechamento.hora': e.fechamento.hora,
        'fechamento.preco': e.fechamento.preco,
        'fechamento.nivel': this.filterFibo(e.fechamento.nivel),
        'fechamento.preco_vwap': e.fechamento.preco_vwap,
        'Nivel vwap fechamento': this.transformNiveis("V", e.fechamento.vwap),
        'fechamento.preco_media': e.fechamento.preco_media,
        'Nivel media fechamento': this.transformNiveis("M", e.fechamento.media),
        'status': e.status,
        'statusNumero': this.transNumero(e.status, e.fechamento.nivel),
        'pontos': e.pontos
      };
      data.push(result);
    });

    this.excelService.exportAsExcelFile(inputs, data, 'fibo');
  }

  
  createDate(date){
    let dia =  new Date(date);
    return new Date(dia.getFullYear(), dia.getMonth(), dia.getDay());
  }

  createTipo(){
    let tipo = this.form.get("tipo").value  ;
    let media = (this.MediaVwap)? "SIM":"NAO";
    let result = tipo + " VWM "+ media;
    return result;
  }

  convertMonth(m){
    if(m=="01"){
      return "Janeiro"
    }else if (m=="02"){
      return "Fevereiro"
    }else if (m=="03"){
      return "Março"
    }else if (m=="04"){
      return "Abril"
    }else if (m=="05"){
      return "Maio"
    }else if (m=="06"){
      return "Junho"
    }else if (m=="07"){
      return "Julho"
    }else if (m=="08"){
      return "Agosto"
    }else if (m=="09"){
      return "Setembro"
    }else if (m=="10"){
      return "Outubro"
    }else if (m=="11"){
      return "Novembro"
    }else{
      return "Dezembro"
    }
  }

  precoMedia(abertura, media){
    let m = media.split(",");
    if(abertura > m[0]){
      let x = ((abertura-1)-(parseInt(m[0])+1));
      return Math.ceil((x/3))+"_Niveis_abaixo";
    }else{
      let x = (parseInt(m[1])-1)-(abertura-1);
      return Math.ceil((x/3))+"_Niveis_acima";
    }
  }

  transNumero(status, nivel){
    if(status == "profit"){
      if(nivel == 22 || nivel == 34){
        return 1.23;
      }else{
        return 1;
      }
    }else if (status == "stop"){
      if(nivel == 19 || nivel == 31){
        return -1.23;
      }else{
        return -1;
      }
    }else{
      return 1+"_"+status;
    }
  }
}
