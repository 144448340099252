import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';

import { AppComponent } from './app.component';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms'
import { HttpClientModule } from '@angular/common/http';
import { DatePipe, registerLocaleData } from '@angular/common';
import localept from '@angular/common/locales/pt';
registerLocaleData(localept, 'pt');

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MainNavComponent } from './main-nav/main-nav.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { EstatisticaComponent } from './estatistica/estatistica.component';
import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import {
  MAT_DATE_LOCALE, 
  MatFormFieldModule, 
  MatInputModule,
  MatSelectModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatCardModule,
  MatTableModule,
  MatGridListModule,
  MatRadioModule,
  MatExpansionModule,
  MatListModule
} from '@angular/material';
import { EventoDialogComponent } from './dashboard/evento-dialog/evento-dialog.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { Estatistica2Component } from './estatistica2/estatistica2.component';
import { EstatisticavwmComponent } from './estatisticavwm/estatisticavwm.component';
import { MultivwmComponent } from './multivwm/multivwm.component';

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    EstatisticaComponent,
    MainNavComponent,
    EventoDialogComponent,
    Estatistica2Component,
    EstatisticavwmComponent,
    MultivwmComponent
  ],
  entryComponents: [
    EventoDialogComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    ReactiveFormsModule,
    RouterModule.forRoot([
      { path: '', component: DashboardComponent },
      { path: 'estatistica', component: EstatisticaComponent},
      { path: 'estatistica2', component: Estatistica2Component},
      { path: 'estatisticavwm', component: EstatisticavwmComponent},
      { path: 'multivwm', component: MultivwmComponent}
    ], {useHash: true}),
    FormsModule,
    BrowserAnimationsModule,
    LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatCardModule,
    MatTableModule,
    MatGridListModule,
    MatRadioModule,
    FlexLayoutModule,
    MatExpansionModule,
    MatListModule
  ],
  providers: [
    DatePipe,
    { provide: LOCALE_ID, useValue: "pt" },
    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
