import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { BotService } from '../../services/bot.service';

@Component({
  selector: 'app-evento-dialog',
  templateUrl: './evento-dialog.component.html',
  styleUrls: ['./evento-dialog.component.css']
})
export class EventoDialogComponent implements OnInit {
  evento:any = [] ;
  niveis:any = [];
  fibo:any = ["-11.8", "0", "26.4", "38.2", "50", "61.8", "73.6", "88.2", "100", "111.8", "123.6", "138.2", "150", "161.8", "173.6", "185,4", "200"]
  constructor(
    private bot: BotService,
    public dialogRef: MatDialogRef<EventoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
    ) {
      this.bot.getEventos(data.id).subscribe((res:any) => {
        this.evento = res.data;
        this.niveis = [
          {
            nivel : '-11.8',
            coluna: 'nivel_0'
          },
          {
            nivel : '0',
            coluna: 'nivel_1'
          },
          {
            nivel : '23.6',
            coluna: 'nivel_2'
          },
          {
            nivel : '38.2',
            coluna: 'nivel_3'
          },
          {
            nivel : '50',
            coluna: 'nivel_4'
          },
          {
            nivel : '61.8',
            coluna: 'nivel_5'
          },
          {
            nivel : '73.6',
            coluna: 'nivel_6'
          },
          {
            nivel : '88.2',
            coluna: 'nivel_7'
          },
          {
            nivel : '100',
            coluna: 'nivel_8'
          },
          {
            nivel : '111.8',
            coluna: 'nivel_9'
          },
          {
            nivel : '123.6',
            coluna: 'nivel_10'
          },
          {
            nivel : '138.2',
            coluna: 'nivel_11'
          },
          {
            nivel : '150',
            coluna: 'nivel_12'
          },
          {
            nivel : '161.8',
            coluna: 'nivel_13'
          },
          {
            nivel : '173.6',
            coluna: 'nivel_14'
          },
          {
            nivel : '185.4',
            coluna: 'nivel_15'
          },
          {
            nivel : '200',
            coluna: 'nivel_16'
          }
        ]
      })
    }

  ngOnInit() {
  }

}
