import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { animate, state, style, transition, trigger} from '@angular/animations';
import { MatDialog} from '@angular/material/dialog';
import { EventoDialogComponent} from './evento-dialog/evento-dialog.component';
import { BotService } from '../services/bot.service';
import { StickyStyler } from '@angular/cdk/table';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})

export class DashboardComponent implements OnInit {

  fibos:any = [];
  form;

  stats:any  = [
    {
      fibo: 'alta', 
      op:'compra', 
      vals: { tp: 0, tp_time: 0, be: 0, sl: 0, sl_time: 0, pts: 0 },
    },
    {
      fibo: 'alta', 
      op:'venda', 
      vals: { tp: 0, tp_time: 0, be: 0, sl: 0, sl_time: 0, pts: 0 },
    },
    {
      fibo: 'baixa', 
      op:'compra', 
      vals: { tp: 0, tp_time: 0, be: 0, sl: 0, sl_time: 0, pts: 0 },
    },
    {
      fibo: 'baixa', 
      op:'venda', 
      vals: { tp: 0, tp_time: 0, be: 0, sl: 0, sl_time: 0, pts: 0 },
    },
  ]

  engolfados:any = {
    sim:{
      tp:0, sl:0, be:0
    },
    nao:{
      tp:0, sl:0, be:0
    }
  }

  sequencias:any = {
    tp: [],
    sl: [],
    be: [],
  }

  last:any = {
    status: '',
    temp: []
  };
  
  dataSource:any = [];
  columnsToDisplay = ['dia', 'pts' ];
  expandedElement: any | null;
  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;

  constructor(
    private formBuilder: FormBuilder,
    private bot: BotService,
    public dialog: MatDialog
    ) { 
      this.loadFibos();
      this.form = this.formBuilder.group({
        tipo: '',
        start: '',
        end: ''
      })
    }
  
  loadFibos() {
    this.bot.getTiposFibos().subscribe((res:any) =>{
      this.fibos = res.data.fibo;
    });
  }

  ngOnInit() {

  }

  soma(){
    let geral:any = {
       tp: 0, tp_time: 0, be: 0, sl: 0, sl_time: 0, pts: 0
    }
    this.stats.forEach(e => {
      geral.tp += e.vals.tp;
      geral.tp_time += e.vals.tp_time;
      geral.sl += e.vals.sl;
      geral.sl_time += e.vals.sl_time;
      geral.be += e.vals.be;
      geral.pts += e.vals.pts;
    });
    return geral;
  }

  onSubmit(data){
    this.reset();
    this.bot.getDateFibos(data).subscribe((res:any) =>{
      this.resultsLength = res.data.length;
      this.dataSource = res.data;
      
      this.dataSource.forEach((dia:any) => {
        dia.fibos.forEach((fibo:any) => {
          if(fibo.eventos.length > 0){
            fibo.eventos.forEach((evento:any) => {
  
              let stat = this.stats.find(d => d.fibo == fibo.direcao && d.op == evento.operacao);
              stat.vals[evento.status] += 1;
              let i = (evento.status == 'tp')? 2:1;
  
              if(fibo.engolfado == true){
                this.engolfados.sim[evento.status] += 1;
              }else{
                this.engolfados.nao[evento.status] += 1;
              }
  
              if(evento.status == 'tp' || evento.status == 'sl' || evento.status == 'be'){
                if(this.last.temp.length == 0 ){
                  this.last.status = evento.status;
                  this.last.temp.push(evento);
                  if(this.sequencias[evento.status].length < this.last.temp.length){
                    this.sequencias[evento.status] = this.last.temp;
                  }
                }else{
                  if(evento.status == this.last.status){
                    this.last.temp.push(evento);
                    if(this.sequencias[evento.status].length < this.last.temp.length){
                      this.sequencias[evento.status] = this.last.temp;
                    }
                  }else{
                    this.last.status = evento.status;
                    this.last.temp = [];
                    this.last.temp.push(evento);
                    if(this.sequencias[evento.status].length < this.last.temp.length){
                      this.sequencias[evento.status] = this.last.temp;
                    }
                  }
                }
              }
              
              if(evento.status == 'tp' || evento.status == 'sl' || evento.status == 'be'){
                if(evento.movimentos.length >= 2){
                  if(evento.operacao == 'venda'){
                    stat.vals.pts += evento.movimentos[0].preco - evento.movimentos[i].preco;
                  }else{
                    stat.vals.pts += evento.movimentos[i].preco - evento.movimentos[0].preco;
                  }
                }
              }
  
            });
          }
        });
      });
    })
  }

  reset(){
    this.stats = [
      {
        fibo: 'alta', 
        op:'compra', 
        vals: { tp: 0, tp_time: 0, be: 0, sl: 0, sl_time: 0, pts: 0 },
      },
      {
        fibo: 'alta', 
        op:'venda', 
        vals: { tp: 0, tp_time: 0, be: 0, sl: 0, sl_time: 0, pts: 0 },
      },
      {
        fibo: 'baixa', 
        op:'compra', 
        vals: { tp: 0, tp_time: 0, be: 0, sl: 0, sl_time: 0, pts: 0 },
      },
      {
        fibo: 'baixa', 
        op:'venda', 
        vals: { tp: 0, tp_time: 0, be: 0, sl: 0, sl_time: 0, pts: 0 },
      },
    ]
    this.sequencias = {
      tp: [],
      sl: [],
      be: []
    }
    this.engolfados = {
      sim:{
        tp:0, sl:0, be:0
      },
      nao:{
        tp:0, sl:0, be:0
      }
    }
    this.last = {
      status: '',
      temp: []
    };
  }

  convertSigla(data){
    let sigla= '';
    if(data == 'sl'){
      sigla = 'Stop Loss';
    }else if(data == 'sl_time'){
      sigla = 'Stop loss - Tempo';
    }else if(data == 'tp'){
      sigla = 'Profit';
    }else if(data == 'tp_time'){
      sigla = 'Profit - Tempo';
    }else if(data == 'be'){
      sigla = 'Break Event';
    }else {
      sigla = 'erro';
    }
    return sigla;
  }

  openDialog(id:any): void {
    const dialogRef = this.dialog.open(EventoDialogComponent, {
      maxWidth: '95vw',
      maxHeight: '95vh',
      width: '90vw',
      height: '95vh',
      data: {id: id}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }


}
