import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DatePipe } from '@angular/common';

export const API_URL: string = 'https://blanchor.dugong.com.br/api'

@Injectable({
  providedIn: 'root'
})
export class BotService {

  constructor(
    private http: HttpClient,
    private datePipe : DatePipe
  ) { }

  getAllFibos (){
    const uri = API_URL + '/fibos/index.json';
    return this.http.get(uri);
  }
  getTiposFibos(){
    const uri = API_URL + '/fibos/tipos.json';
    return this.http.get(uri);
  }
  getDateFibos(data:any){
    let start = this.datePipe.transform(data.start, 'yyyy-MM-dd');
    let end = this.datePipe.transform(data.end, 'yyyy-MM-dd');
    const uri = API_URL + '/fibos/dateNew/'+data.tipo+'/'+start+'/'+end+'.json';
    return this.http.get(uri);
  }
  getFibos (id){
    const uri = API_URL + '/fibos/view/'+id+'.json';
    return this.http.get(uri);
  }
  getAllEventos (){
    const uri = API_URL + '/eventos/index.json';
    return this.http.get(uri);
  }
  getEventos(id){
    const uri = API_URL + '/eventos/view/'+id+'.json';
    return this.http.get(uri);
  }
  getAllMov (){
    const uri = API_URL + '/movimentos/index.json';
    return this.http.get(uri);
  }
  getMov(id){
    const uri = API_URL + '/movimentos/view/'+id+'.json';
    return this.http.get(uri);
  }

  getEstatisticaFibos(){
    const  uri = API_URL + '/estatisticas/tipos.json'
    return this.http.get(uri);
  }

  getEstatisticaNiveis(data){
    const  uri = API_URL + '/estatisticas/niveis/'+data+'.json';
    return this.http.get(uri);
  }

  getEstatisticas(data:any){
    let start = this.datePipe.transform(data.start, 'yyyy-MM-dd');
    let end = this.datePipe.transform(data.end, 'yyyy-MM-dd');
    const uri = API_URL + '/estatisticas/date/'+data.tipo+'/'+start+'/'+end+'/'+data.open+'/'+data.stop+'/'+data.profit+'.json';
    return this.http.get(uri);
  }

  getEstatisticaFibos2(){
    const  uri = API_URL + '/statistics/tipos.json'
    return this.http.get(uri);
  }

  getMultiAtivos(){
    const  uri = API_URL + '/multiStatistics/ativos.json'
    return this.http.get(uri);
  }

  getMultiFibos(){
    const  uri = API_URL + '/multiStatistics/tipos.json'
    return this.http.get(uri);
  }

  getEstatisticaNiveis2(data){
    const  uri = API_URL + '/statistics/niveis/'+data+'.json';
    return this.http.get(uri);
  }

  getMultiNiveis(data){
    const  uri = API_URL + '/multiStatistics/niveis/'+data+'.json';
    return this.http.get(uri);
  }

  getEstatisticas2(data:any){
    let start = this.datePipe.transform(data.start, 'yyyy-MM-dd');
    let end = this.datePipe.transform(data.end, 'yyyy-MM-dd');
    const uri = API_URL + '/statistics/dateNeo/'+data.tipo+'/'+start+'/'+end+'/'+data.stop+'/'+data.profit+'.json';
    return this.http.get(uri);
  }

  getMulti(data:any){
    let start = this.datePipe.transform(data.start, 'yyyy-MM-dd');
    let end = this.datePipe.transform(data.end, 'yyyy-MM-dd');
    const uri = API_URL + '/multiStatistics/dateNeo/'+data.ativo+'/'+data.tipo+'/'+start+'/'+end+'/'+data.stop+'/'+data.profit+'.json';
    return this.http.get(uri);
  }

  getEstatisticasOLD2(data:any){
    let start = this.datePipe.transform(data.start, 'yyyy-MM-dd');
    let end = this.datePipe.transform(data.end, 'yyyy-MM-dd');
    const uri = API_URL + '/statistics/date/'+data.tipo+'/'+start+'/'+end+'/'+data.open+'/'+data.stop+'/'+data.profit+'.json';
    return this.http.get(uri);
  }



}
