import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { animate, state, style, transition, trigger} from '@angular/animations';
import { MatDialog} from '@angular/material/dialog';
import { BotService } from '../services/bot.service';
import { ExcelService } from '../services/excel.service';

import { StickyStyler } from '@angular/cdk/table';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Component({
  selector: 'app-estatistica2',
  templateUrl: './estatistica2.component.html',
  styleUrls: ['./estatistica2.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class Estatistica2Component implements OnInit {

  fibos:any = [];
  estatisticas;
  form;
  open;
  stop;
  profit;
  niveis:any = [];
  
  abertura:any = {}
  fechamento:any = {}
  status

  dataSource:any = [];
  displayedColumns = [
    'abertura.data',
    'abertura.hora', 
    'abertura.preco',
    'fechamento.hora',
    'fechamento.preco',
    'status',
    'pontos'
  ];
  constructor(
    private formBuilder: FormBuilder,
    private bot: BotService,
    public dialog: MatDialog,
    private excelService:ExcelService
  ) {
    this.loadFibos();
    this.form = this.formBuilder.group({
      tipo: '',
      start: '',
      end: '',
      open: '',
      stop: '',
      profit: ''
    })
   }
   
  ngOnInit() {
    this.displayedColumns = [
      'abertura.data',
      'abertura.hora', 
      'abertura.preco',
      'abertura.vwap',
      'abertura.media',
      'fechamento.hora',
      'fechamento.preco',
      'fechamento.vwap',
      'fechamento.media',
      'status',
      'pontos'
    ];
  }

  loadFibos() {
    this.bot.getEstatisticaFibos2().subscribe((res:any) =>{
      this.fibos = res.data;
    });
  }

  onChange(data){
    console.log(data);
    this.bot.getEstatisticaNiveis2(data).subscribe((res:any) =>{
      this.niveis = res.data;
    });
  }

  filterFibo(data){
    let res = this.niveis.filter(d => d.value == data);
    return res[0].nome;
  }

  onSubmit(data){
    this.dataSource = [];
    this.bot.getEstatisticasOLD2(data).subscribe((res:any) =>{
      this.dataSource = res.data;
    });
  }

  transformNiveis(tipo, val){
    let n = val.split(",");
    let up = this.niveis.find(x => x.value == n[0]);
    let down = this.niveis.find(x => x.value == n[1]);
    console.log(up);
    return `${up.nome} > ${tipo} > ${down.nome}`;
  }

  teste(){
  }

  clickExcel(){
    const inputs:any = [{
      'fibo': this.form.get('tipo').value,
      'Inicio': this.form.get('start').value,
      'Final': this.form.get('end').value,
      'Abertura': this.filterFibo(this.form.get('open').value),
      'Stop': this.filterFibo(this.form.get('stop').value),
      'Profit': this.filterFibo(this.form.get('profit').value),
    }];
    const data:any = [];
    this.dataSource.forEach(e => {
      let result:any = {
        'data' : e.abertura.data,
        'abertura.hora': e.abertura.hora,
        'abertura.preco': e.abertura.preco,
        'abertura.vwap': this.transformNiveis("V", e.abertura.vwap),
        'abertura.media': this.transformNiveis("M", e.abertura.media),
        'fechamento.hora': e.fechamento.hora,
        'fechamento.preco': e.fechamento.preco,
        'fechamento.vwap': this.transformNiveis("V", e.fechamento.vwap),
        'fechamento.media': this.transformNiveis("M", e.fechamento.media),
        'status': e.status,
        'pontos': e.pontos
      };
      data.push(result);
    });

    this.excelService.exportAsExcelFile(inputs, data, 'fibo');
  }
}
